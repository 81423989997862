import React from 'react';
import OpenMailApp from './OpenMailApp';

export const Career = (props) => {

 
  
  return (
    <div id="career" className="text-center">
      <div className="container">      
        <div className="section-title">
          <h2>KARİYER</h2>
          <p>
            Kariyerinizi bizimle şekillendirmek isterseniz cv paylaşabilirsiniz.
          </p>
          <OpenMailApp/>        
        </div>
      </div>
    </div>
  );

};
