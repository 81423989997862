import React, { Component } from 'react';

export default class OpenMailApp extends Component {

  handleMailClick() {
    const email = 'info@peaksoftcon.com';
    const subject = 'CV!';
    const body = 'Hi, You can find my cv on attachment.';

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${body}`;
  }

  render() {
    return (
                
        <div className="row" >
            <button className="btn btn-custom btn-lg" onClick={this.handleMailClick}>
              Mail Gönder
            </button>
        </div>
      
    );
  }
}
